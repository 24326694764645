import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'app-editentryadim2',
	templateUrl: './editentryadim2.component.html',
	styleUrls: ['./editentryadim2.component.css']
})
export class EditentryAdim2Component implements OnInit {
	Yil = '';
	GlobalYonetim: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	Kategoriler: any;
	IlkSurdurulebilirlik = 0;
	SonSurdurulebilirlik = 0;
	Yuklendi = false;
	TempSubKategoriler: any;

	Markalar: any;
	Reklamverenler: any;
	ReklamverenHolding: any;
	Ajanslar: any;
	BasvuranNetwork: any;
	BasvuranHolding: any;

	ReklamverenYetkiliSilSwal = {
		title: 'Emin misiniz?',
		text: 'Reklamveren Yetkilisi Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	BasvuranAjansYetkiliSilSwal = {
		title: 'Emin misiniz?',
		text: 'Basvuran Ana Ajans Yetkilisi Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	BasvuranAjansIkiYetkiliSilSwal = {
		title: 'Emin misiniz?',
		text: 'Basvuran Ana Ajans Yetkilisi Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	KatkidaBulunanAjansSilSwal = {
		title: 'Emin misiniz?',
		text: 'Katkıda Bulunan Ajans Silmek Üzeresiniz',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	YeniReklamverenYetkiliAdi = '';
	YeniReklamverenYetkiliEposta = '';

	YeniBasvuranAjansYetkiliAdi = '';
	YeniBasvuranAjansYetkiliEposta = '';

	YeniBasvuranAjansIkiYetkiliAdi = '';
	YeniBasvuranAjansIkiYetkiliEposta = '';

	ModalTitle: string = '';
	ModalData: Object = [];

	SeciliMarka: string | null;
	SeciliReklamveren: string | null;
	SeciliReklamverenHolding: string | null;
	SeciliAjansAdi: string | null;
	SeciliBasvuranNetwork: string | null;
	SeciliBasvuranHolding: string | null;
	SeciliKatkidaBulunanAjans1: string | null;
	SeciliKatkidaBulunanAjans2: string | null;
	SeciliKatkidaBulunanAjans3: string | null;
	SeciliKatkidaBulunanAjans4: string | null;
	SeciliKatkidaBulunanNetwork1: string | null;
	SeciliKatkidaBulunanHolding1: string | null;
	SeciliKatkidaBulunanNetwork2: string | null;
	SeciliKatkidaBulunanHolding2: string | null;
	SeciliKatkidaBulunanNetwork3: string | null;
	SeciliKatkidaBulunanHolding3: string | null;
	SeciliKatkidaBulunanNetwork4: string | null;
	SeciliKatkidaBulunanHolding4: string | null;

	KatkidaBulunanAjans = {
		id: 0,
		ProjeId: 0,
		Ajans: '',
		Il: '',
		Ulke: '',
		Web: '',
		Network: '',
		Holding: ''
	};

	@ViewChild("MarkaSelect", { static: false }) MarkaSelect: NgSelectComponent;
	@ViewChild("MarkaInput", { static: false }) MarkaInput: ElementRef;
	@ViewChild("AjansSelect", { static: false }) AjansSelect: NgSelectComponent;
	@ViewChild("AjansInput", { static: false }) AjansInput: ElementRef;
	@ViewChild("ReklamSelect", { static: false }) ReklamSelect: NgSelectComponent;
	@ViewChild("ReklamInput", { static: false }) ReklamInput: ElementRef;
	@ViewChild("SelectReklamverenHolding", { static: false }) SelectReklamverenHolding: NgSelectComponent;
	@ViewChild("InputReklamverenHolding", { static: false }) InputReklamverenHolding: ElementRef;
	@ViewChild("SelectBasvuranNetwork", { static: false }) SelectBasvuranNetwork: NgSelectComponent;
	@ViewChild("InputBasvuranNetwork", { static: false }) InputBasvuranNetwork: ElementRef;
	@ViewChild("SelectBasvuranHolding", { static: false }) SelectBasvuranHolding: NgSelectComponent;
	@ViewChild("InputBasvuranHolding", { static: false }) InputBasvuranHolding: ElementRef;

	@ViewChild("SelectKatkidaBulunanNetwork1", { static: false }) SelectKatkidaBulunanNetwork1: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanNetwork1", { static: false }) InputKatkidaBulunanNetwork1: ElementRef;
	@ViewChild("SelectKatkidaBulunanHolding1", { static: false }) SelectKatkidaBulunanHolding1: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanHolding1", { static: false }) InputKatkidaBulunanHolding1: ElementRef;

	@ViewChild("SelectKatkidaBulunanNetwork2", { static: false }) SelectKatkidaBulunanNetwork2: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanNetwork2", { static: false }) InputKatkidaBulunanNetwork2: ElementRef;
	@ViewChild("SelectKatkidaBulunanHolding2", { static: false }) SelectKatkidaBulunanHolding2: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanHolding2", { static: false }) InputKatkidaBulunanHolding2: ElementRef;

	@ViewChild("SelectKatkidaBulunanNetwork3", { static: false }) SelectKatkidaBulunanNetwork3: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanNetwork3", { static: false }) InputKatkidaBulunanNetwork3: ElementRef;
	@ViewChild("SelectKatkidaBulunanHolding3", { static: false }) SelectKatkidaBulunanHolding3: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanHolding3", { static: false }) InputKatkidaBulunanHolding3: ElementRef;

	@ViewChild("SelectKatkidaBulunanNetwork4", { static: false }) SelectKatkidaBulunanNetwork4: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanNetwork4", { static: false }) InputKatkidaBulunanNetwork4: ElementRef;
	@ViewChild("SelectKatkidaBulunanHolding4", { static: false }) SelectKatkidaBulunanHolding4: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanHolding4", { static: false }) InputKatkidaBulunanHolding4: ElementRef;

	@ViewChild("SelectKatkidaBulunanAjans1", { static: false }) SelectKatkidaBulunanAjans1: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanAjans1", { static: false }) InputKatkidaBulunanAjans1: ElementRef;
	@ViewChild("SelectKatkidaBulunanAjans2", { static: false }) SelectKatkidaBulunanAjans2: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanAjans2", { static: false }) InputKatkidaBulunanAjans2: ElementRef;
	@ViewChild("SelectKatkidaBulunanAjans3", { static: false }) SelectKatkidaBulunanAjans3: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanAjans3", { static: false }) InputKatkidaBulunanAjans3: ElementRef;
	@ViewChild("SelectKatkidaBulunanAjans4", { static: false }) SelectKatkidaBulunanAjans4: NgSelectComponent;
	@ViewChild("InputKatkidaBulunanAjans4", { static: false }) InputKatkidaBulunanAjans4: ElementRef;

	constructor(
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private zone: NgZone,
		private globals: Globals,
		private messageService: MessageServiceService,
		private _sanitizer:DomSanitizer
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			// console.log("asdas");
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			// this.Kategoriler = this.globals.Kategoriler;

			// console.log(this.Kategoriler);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			// console.log("asdas2");
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				// console.log("bbb " + this.Yil);
				// console.log(this.Kategoriler);
				// console.log(this.GlobalYonetim);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.Adim11Durum === 1) {
				this.zone.run(() => {
					this.router.navigateByUrl('/dashboard');
				});
			}
			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			let that = this;
			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];

			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			// this.Kategoriler = this.globals.Kategoriler;
			this.Kategoriler.forEach(element => {
				// console.log(element)
				if (element.Id == this.PageData.KategoriId) {
					this.TempSubKategoriler = element.SubKategoriler;
				}
			});
			if (this.PageData.ReklamverenYetkililer.length === 0) {
				var temp = {};
				temp['AdSoyad'] = this.YeniReklamverenYetkiliAdi.trim();
				temp['Eposta'] = this.YeniReklamverenYetkiliEposta.trim();
				this.PageData.ReklamverenYetkililer.push(temp);
			}
			if (this.PageData.BasvuranAjansYetkililer.length === 0) {
				var temp = {};
				temp['AdSoyad'] = this.YeniBasvuranAjansYetkiliAdi.trim();
				temp['Eposta'] = this.YeniBasvuranAjansYetkiliEposta.trim();
				this.PageData.BasvuranAjansYetkililer.push(temp);
			}
			if (this.PageData.BasvuranAjansIkiYetkililer.length === 0) {
				var temp = {};
				temp['AdSoyad'] = this.YeniBasvuranAjansIkiYetkiliAdi.trim();
				temp['Eposta'] = this.YeniBasvuranAjansIkiYetkiliEposta.trim();
				this.PageData.BasvuranAjansIkiYetkililer.push(temp);
			}
			this.Yuklendi = true;
			// console.log(this.PageData)
			// console.log(this.GlobalYonetim)
			// console.log(this.GlobalYonetim.Ajanslar.split("\n"));

			this.Markalar = this.GlobalYonetim.Markalar.split("\n");
			this.Markalar.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.Markalar); // use arr as this
			if (this.Markalar.includes(this.PageData.MarkaAdi)) {
				this.SeciliMarka = this.PageData.MarkaAdi;
			}

			this.Reklamverenler = this.GlobalYonetim.Reklamverenler.split("\n");
			this.Reklamverenler.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.Reklamverenler); // use arr as this
			if (this.Reklamverenler.includes(this.PageData.ReklamverenAdi.trim())) {
				this.SeciliReklamveren = this.PageData.ReklamverenAdi.trim();
			}

			this.ReklamverenHolding = this.GlobalYonetim.ReklamverenHolding.split("\n");
			this.ReklamverenHolding.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.ReklamverenHolding); // use arr as this
			if (this.ReklamverenHolding.includes(this.PageData.ReklamverenHolding)) {
				this.SeciliReklamverenHolding = this.PageData.ReklamverenHolding;
			}

			this.Ajanslar = this.GlobalYonetim.Ajanslar.split("\n");
			this.Ajanslar.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.Ajanslar); // use arr as this
			if (this.Ajanslar.includes(this.PageData.BasvuranAjansAdi)) {
				this.SeciliAjansAdi = this.PageData.BasvuranAjansAdi;
			}

			this.BasvuranNetwork = this.GlobalYonetim.BasvuranNetwork.split("\n");
			this.BasvuranNetwork.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.BasvuranNetwork); // use arr as this
			if (this.BasvuranNetwork.includes(this.PageData.BasvuranAjansNetwork)) {
				this.SeciliBasvuranNetwork = this.PageData.BasvuranAjansNetwork;
			}

			this.BasvuranHolding = this.GlobalYonetim.BasvuranHolding.split("\n");
			this.BasvuranHolding.forEach(function (part, index) {
				this[index] = this[index].trim();
			}, this.BasvuranHolding); // use arr as this
			if (this.BasvuranHolding.includes(this.PageData.BasvuranAjansHolding)) {
				this.SeciliBasvuranHolding = this.PageData.BasvuranAjansHolding;
			}

			
			if (this.PageData.KatkidaBulunanAjanslar.length > 0) {
				if (this.Ajanslar.includes(this.PageData.KatkidaBulunanAjanslar[0].Ajans)) {
					this.SeciliKatkidaBulunanAjans1 = this.PageData.KatkidaBulunanAjanslar[0].Ajans;
				}
				if (this.BasvuranNetwork.includes(this.PageData.KatkidaBulunanAjanslar[0].Network)) {
					this.SeciliKatkidaBulunanNetwork1 = this.PageData.KatkidaBulunanAjanslar[0].Network;
				}
				if (this.BasvuranHolding.includes(this.PageData.KatkidaBulunanAjanslar[0].Holding)) {
					this.SeciliKatkidaBulunanHolding1 = this.PageData.KatkidaBulunanAjanslar[0].Holding;
				}
			}
			if (this.PageData.KatkidaBulunanAjanslar.length > 1) {
				if (this.Ajanslar.includes(this.PageData.KatkidaBulunanAjanslar[1].Ajans)) {
					this.SeciliKatkidaBulunanAjans2 = this.PageData.KatkidaBulunanAjanslar[1].Ajans;
				}
				if (this.BasvuranNetwork.includes(this.PageData.KatkidaBulunanAjanslar[1].Network)) {
					this.SeciliKatkidaBulunanNetwork2 = this.PageData.KatkidaBulunanAjanslar[1].Network;
				}
				if (this.BasvuranHolding.includes(this.PageData.KatkidaBulunanAjanslar[1].Holding)) {
					this.SeciliKatkidaBulunanHolding2 = this.PageData.KatkidaBulunanAjanslar[1].Holding;
				}
			}
			if (this.PageData.KatkidaBulunanAjanslar.length > 2) {
				if (this.Ajanslar.includes(this.PageData.KatkidaBulunanAjanslar[2].Ajans)) {
					this.SeciliKatkidaBulunanAjans3 = this.PageData.KatkidaBulunanAjanslar[2].Ajans;
				}
				if (this.BasvuranNetwork.includes(this.PageData.KatkidaBulunanAjanslar[2].Network)) {
					this.SeciliKatkidaBulunanNetwork3 = this.PageData.KatkidaBulunanAjanslar[2].Network;
				}
				if (this.BasvuranHolding.includes(this.PageData.KatkidaBulunanAjanslar[2].Holding)) {
					this.SeciliKatkidaBulunanHolding3 = this.PageData.KatkidaBulunanAjanslar[2].Holding;
				}
			}
			if (this.PageData.KatkidaBulunanAjanslar.length > 3) {
				if (this.Ajanslar.includes(this.PageData.KatkidaBulunanAjanslar[3].Ajans)) {
					this.SeciliKatkidaBulunanAjans4 = this.PageData.KatkidaBulunanAjanslar[3].Ajans;
				}
				if (this.BasvuranNetwork.includes(this.PageData.KatkidaBulunanAjanslar[3].Network)) {
					this.SeciliKatkidaBulunanNetwork4 = this.PageData.KatkidaBulunanAjanslar[3].Network;
				}
				if (this.BasvuranHolding.includes(this.PageData.KatkidaBulunanAjanslar[3].Holding)) {
					this.SeciliKatkidaBulunanHolding4 = this.PageData.KatkidaBulunanAjanslar[3].Holding;
				}
			}

			// setTimeout(() => {
			// 	console.log(this.AjansSelect);
			// 	this.AjansSelect.open();	
			// }, 1000);

			// console.log(this.SeciliAjansAdi)
		}
	}

	CreateNewAjans() {
		// console.log(this.AjansSelect)
		this.SeciliAjansAdi = " ";
		this.AjansSelect.close();
		setTimeout(() => {
			this.AjansInput.nativeElement.focus();
		}, 300);
	}

	CreateNewMarka() {
		// console.log(this.MarkaSelect)
		this.SeciliMarka = " ";
		this.MarkaSelect.close();
		setTimeout(() => {
			this.MarkaInput.nativeElement.focus();
		}, 300);
	}
	MarkaChanged($event) {
		// console.log($event);
		// console.log($event.target);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.MarkaAdi = '';
			}
			else {
				this.PageData.MarkaAdi = $event;
			}
		}
	}

	CreateNewReklamveren() {
		// console.log(this.MarkaSelect)
		this.SeciliReklamveren = " ";
		this.ReklamSelect.close();
		setTimeout(() => {
			this.ReklamInput.nativeElement.focus();
		}, 300);
	}
	ReklamverenChanged($event) {
		// console.log($event);
		// console.log($event.target);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.ReklamverenAdi = '';
			}
			else {
				this.PageData.ReklamverenAdi = $event;
			}
		}
	}

	CreateNewReklamverenHolding() {
		this.SeciliReklamverenHolding = " ";
		setTimeout(() => {
			this.SelectReklamverenHolding.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputReklamverenHolding.nativeElement.focus();
		}, 300);
	}
	ReklamverenHoldingChanged($event) {
		// console.log($event);
		// console.log($event.target);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.ReklamverenHolding = '';
			}
			else {
				this.PageData.ReklamverenHolding = $event;
			}
		}
	}

	CreateNewBasvuranNetwork() {
		// console.log(this.MarkaSelect)
		this.SeciliBasvuranNetwork = " ";
		this.ReklamSelect.close();
		setTimeout(() => {
			this.InputBasvuranNetwork.nativeElement.focus();
		}, 300);
	}
	BasvuranNetworkChanged($event) {
		// console.log($event);
		// console.log($event.target);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.BasvuranAjansNetwork = '';
			}
			else {
				this.PageData.BasvuranAjansNetwork = $event;
			}
		}
	}

	CreateNewBasvuranHolding() {
		// console.log(this.MarkaSelect)
		this.SeciliBasvuranHolding = " ";
		this.ReklamSelect.close();
		setTimeout(() => {
			this.InputBasvuranHolding.nativeElement.focus();
		}, 300);
	}
	BasvuranHoldingChanged($event) {
		// console.log($event);
		// console.log($event.target);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.BasvuranAjansHolding = '';
			}
			else {
				this.PageData.BasvuranAjansHolding = $event;
			}
		}
	}

	CreateNewKatkidaBulunanNetwork1() {
		this.SeciliKatkidaBulunanNetwork1 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork1.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanNetwork1.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanNetwork1Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[0].Network = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork1.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanHolding1() {
		this.SeciliKatkidaBulunanHolding1 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding1.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanHolding1.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanHolding1Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[0].Holding = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding1.blur(); // OK
		}, 1);
	}

	CreateNewKatkidaBulunanNetwork2() {
		this.SeciliKatkidaBulunanNetwork2 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork2.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanNetwork2.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanNetwork2Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[1].Network = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork2.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanHolding2() {
		this.SeciliKatkidaBulunanHolding2 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding2.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanHolding2.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanHolding2Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[1].Holding = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding2.blur(); // OK
		}, 1);
	}

	CreateNewKatkidaBulunanNetwork3() {
		this.SeciliKatkidaBulunanNetwork3 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork3.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanNetwork3.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanNetwork3Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[2].Network = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork3.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanHolding3() {
		this.SeciliKatkidaBulunanHolding3 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding3.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanHolding3.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanHolding3Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[2].Holding = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding3.blur(); // OK
		}, 1);
	}

	CreateNewKatkidaBulunanNetwork4() {
		this.SeciliKatkidaBulunanNetwork4 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork4.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanNetwork4.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanNetwork4Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[3].Network = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanNetwork4.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanHolding4() {
		this.SeciliKatkidaBulunanHolding4 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding4.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanHolding4.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanHolding4Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[3].Holding = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanHolding4.blur(); // OK
		}, 1);
	}

	CreateNewKatkidaBulunanAjans1() {
		this.SeciliKatkidaBulunanAjans1 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans1.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanAjans1.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanAjans1Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[0].Ajans = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans1.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanAjans2() {
		this.SeciliKatkidaBulunanAjans2 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans2.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanAjans2.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanAjans2Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[1].Ajans = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans2.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanAjans3() {
		this.SeciliKatkidaBulunanAjans3 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans3.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanAjans3.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanAjans3Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[2].Ajans = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans3.blur(); // OK
		}, 1);
	}
	CreateNewKatkidaBulunanAjans4() {
		this.SeciliKatkidaBulunanAjans4 = " ";
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans4.close(); // OK
		}, 1);
		setTimeout(() => {
			this.InputKatkidaBulunanAjans4.nativeElement.focus();
		}, 300);
	}
	KatkidaBulunanAjans4Changed($event: any) {
		this.PageData.KatkidaBulunanAjanslar[3].Ajans = $event;
		setTimeout(() => {
			this.SelectKatkidaBulunanAjans4.blur(); // OK
		}, 1);
	}

	ModalOpen(content) {
		// console.log(content);
		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			if (result === 'SaveAndClose') {
				this.YeniReklamverenYetkiliAdi = '';
				this.YeniReklamverenYetkiliEposta = '';
				this.YeniBasvuranAjansYetkiliAdi = '';
				this.YeniBasvuranAjansYetkiliEposta = '';
				this.YeniBasvuranAjansIkiYetkiliAdi = '';
				this.YeniBasvuranAjansIkiYetkiliEposta = '';
			}
			else {
				this.YeniReklamverenYetkiliAdi = '';
				this.YeniReklamverenYetkiliEposta = '';
				this.YeniBasvuranAjansYetkiliAdi = '';
				this.YeniBasvuranAjansYetkiliEposta = '';
				this.YeniBasvuranAjansIkiYetkiliAdi = '';
				this.YeniBasvuranAjansIkiYetkiliEposta = '';
			}
		}, (reason) => {
			this.YeniReklamverenYetkiliAdi = '';
			this.YeniReklamverenYetkiliEposta = '';
			this.YeniBasvuranAjansYetkiliAdi = '';
			this.YeniBasvuranAjansYetkiliEposta = '';
			this.YeniBasvuranAjansIkiYetkiliAdi = '';
			this.YeniBasvuranAjansIkiYetkiliEposta = '';
		});
	}

	KategoriChanged($event) {
		this.TempSubKategoriler = [];

		this.Kategoriler.forEach(element => {
			var say = 0;
			if (element.Id == this.PageData.KategoriId) {
				element['SubKategoriler'].forEach(SubElement => {
					if (say === 0) {
						this.PageData['SubKategoriId'] = SubElement.Id;
						this.SonSurdurulebilirlik = SubElement.Surdurulebilir;
						this.PageData.Surdurulebilir = element.Surdurulebilir;
					}
					this.TempSubKategoriler.push(SubElement);
					say++;
				});
			}
		});
		if (this.IlkSurdurulebilirlik != this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}

	}

	SubKategoriChanged($event) {
		this.TempSubKategoriler.forEach(element => {

			if (element.Id == this.PageData.SubKategoriId) {
				this.PageData['SubKategoriId'] = element.Id;
				this.SonSurdurulebilirlik = element.Surdurulebilir;
				this.PageData.Surdurulebilir = element.Surdurulebilir;
			}
		});
		if (this.IlkSurdurulebilirlik != this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}
	}


	AnaAjansChanged($event) {
		// console.log(this.SeciliAjansAdi);
		if ($event != 0) {
			if ($event == 'Diğer') {
				this.PageData.BasvuranAjansAdi = '';
			}
			else {
				this.PageData.BasvuranAjansAdi = $event;
			}
		}
	}

	AnaAjansIkiChanged($event) {
		if ($event != 0) {
			this.PageData.BasvuranAjansIkiAdi = $event;
		}
	}


	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	ReklamverenYetkiliSil(Index) {
		if (this.PageData.ReklamverenYetkililer.length === 1) {
			this.showWarning('En az 1 adet Reklamveren Yetkilisi Girmelisiniz.')
			return;
		}
		else {
			this.PageData.ReklamverenYetkililer.splice(Index, 1);
			this.showSuccess('Reklamveren Yetkilisi silindi.');
		}
	}

	ReklamverenYeniModalSave() {
		if (this.YeniReklamverenYetkiliAdi.trim().length === 0) {
			this.showWarning('Yetkili Adı Girmelisiniz.');
			return;
		}
		if (this.YeniReklamverenYetkiliEposta.trim().length === 0) {
			this.showWarning('Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		if (!this.validateEmail(this.YeniReklamverenYetkiliEposta.trim())) {
			this.showWarning('Geçerli Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}

		var temp = {};
		temp['AdSoyad'] = this.YeniReklamverenYetkiliAdi.trim();
		temp['Eposta'] = this.YeniReklamverenYetkiliEposta.trim();
		this.PageData.ReklamverenYetkililer.push(temp);
		this.showSuccess('Reklamveren Yetkilisi eklendi.');
		this.modalService.dismissAll('');
		temp = null;
	}

	BasvuranAjansYetkiliSil(Index) {
		if (this.PageData.BasvuranAjansYetkililer.length === 1) {
			this.showWarning('En az 1 adet Ajans Yetkilisi Girmelisiniz.');
			return;
		}
		else {
			this.PageData.BasvuranAjansYetkililer.splice(Index, 1);
			this.showSuccess('Ajans Yetkilisi silindi.');
		}
	}

	BasvuranAjansYeniModalSave() {
		if (this.YeniBasvuranAjansYetkiliAdi.trim().length === 0) {
			this.showWarning('Yetkili Adı Girmelisiniz.');
			return;
		}
		if (this.YeniBasvuranAjansYetkiliEposta.trim().length === 0) {
			this.showWarning('Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		if (!this.validateEmail(this.YeniBasvuranAjansYetkiliEposta.trim())) {
			this.showWarning('Geçerli Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		var temp = {};
		temp['AdSoyad'] = this.YeniBasvuranAjansYetkiliAdi.trim();
		temp['Eposta'] = this.YeniBasvuranAjansYetkiliEposta.trim();
		this.PageData.BasvuranAjansYetkililer.push(temp);
		this.showSuccess('Ajans Yetkilisi eklendi.');
		this.modalService.dismissAll('');
		temp = null;
	}

	BasvuranAjansIkiYeniModalSave() {
		if (this.YeniBasvuranAjansIkiYetkiliAdi.trim().length === 0) {
			this.showWarning('Yetkili Adı Girmelisiniz.');
			return;
		}
		if (this.YeniBasvuranAjansIkiYetkiliEposta.trim().length === 0) {
			this.showWarning('Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		if (!this.validateEmail(this.YeniBasvuranAjansIkiYetkiliEposta.trim())) {
			this.showWarning('Geçerli Yetkili E-Posta Adresi Girmelisiniz.');
			return;
		}
		var temp = {};
		temp['AdSoyad'] = this.YeniBasvuranAjansIkiYetkiliAdi.trim();
		temp['Eposta'] = this.YeniBasvuranAjansIkiYetkiliEposta.trim();
		this.PageData.BasvuranAjansIkiYetkililer.push(temp);
		this.showSuccess('Ajans Yetkilisi eklendi.');
		this.modalService.dismissAll('');
		temp = null;
	}

	BasvuranAjansIkiYetkiliSil(Index) {
		this.PageData.BasvuranAjansIkiYetkililer.splice(Index, 1);
		this.showSuccess('Ajans Yetkilisi silindi.');
	}

	KatkidaBulunanAjansSil(Index) {
		this.PageData.KatkidaBulunanAjanslar.splice(Index, 1);
		this.showSuccess('Katkıda Bulunan Ajans silindi.');
	}

	validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	phoneMask(event) {
		console.log(event);
		var num = event.replace(/\D/g, '');
		num = num.replace('(', '');
		num = num.replace(')', '');
		num = num.replace('-', '');

		var yeni = '';
		if (num.substring(0, 1) === '0') {
			this.PageData['Adim2'].FormuDolduran.Tel = yeni;
			this.showError('0 ile başlayamaz');
			return;
		}
		if (num.trim().length < 1) {
			yeni = '';
		}
		else if (num.trim().length >= 1 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3);
		}
		else if (num.trim().length >= 4 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3) + ') ';
		}
		else if (num.trim().length >= 4 && num.trim().length < 7) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6);
		}
		else if (num.trim().length >= 7 && num.trim().length < 9) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8);
		}
		else if (num.trim().length >= 9 && num.trim().length < 11) {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}
		else {
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}

		this.PageData['Adim2'].FormuDolduran.Tel = yeni;

	}

	async KatkidaBulunanAjansEkle() {
		const KatkidaBulunanAjans = {
			id: 0,
			ProjeId: 0,
			Ajans: '',
			Il: 'İstanbul',
			Ulke: 'Türkiye',
			Web: '',
			Network: '',
			Holding: ''
		};
		this.PageData.KatkidaBulunanAjanslar.push(KatkidaBulunanAjans);
	}

	AdimDegistir(sayfa: number) {
		// console.log(this.PageData)
		// return;
		if (typeof sayfa !== 'undefined') {


			let hata = false;
			if (this.PageData.MarkaAdi.trim().length === 0) {
				this.showWarning('Marka Adı Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.MarkaIl.trim().length === 0) {
				this.showWarning('Marka İli Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.MarkaUlke.trim().length === 0) {
				this.showWarning('Marka Ülkesi Girmelisiniz.');
				hata = true;
				return;
			}

			if (this.PageData.KampanyaAdi.trim().length === 0) {
				this.showWarning('Kampanya Adı Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenAdi.trim().length === 0) {
				this.showWarning('Reklamveren Adı Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenAdres.trim().length === 0) {
				this.showWarning('Reklamveren Adresi Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenIl.trim().length === 0) {
				this.showWarning('Reklamveren İli Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenUlke.trim().length === 0) {
				this.showWarning('Reklamveren Ülkesi Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenHolding.trim().length === 0) {
				this.showWarning('Reklamveren Network & Holding Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.ReklamverenWeb.trim().length === 0) {
				this.showWarning('Reklamveren Web Sitesi Girmelisiniz.');
				hata = true;
				return;
			}

			if (this.PageData.ReklamverenYetkililer.length === 0) {
				this.showWarning('En az 1 adet Reklamveren Yetkilisi Girmelisiniz.');
				hata = true;
				return;
			}
			for (let index = 0; index < this.PageData.ReklamverenYetkililer.length; index++) {
				const element = this.PageData.ReklamverenYetkililer[index];
				if (element.AdSoyad.trim().length === 0 || element.Eposta.trim().length === 0) {
					hata = true;
				}
			}
			if (hata === true) {
				this.showWarning('Reklamveren Yetkilisi Bilgilerini Doldurunuz.');
				return;
			}

			for (let index = 0; index < this.PageData.ReklamverenYetkililer.length; index++) {
				const element = this.PageData.ReklamverenYetkililer[index];

				if (!this.validateEmail(element.Eposta.trim())) {
					hata = true;
				}
			}
			if (hata === true) {
				this.showWarning('Geçerli Reklamveren Yetkilisi E-Posta Adresi Girmelisiniz.');
				return;
			}


			if (this.PageData.BasvuranAjansAdi.trim().length === 0) {
				this.showWarning('Ajans Adı Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansAdres.trim().length === 0) {
				this.showWarning('Ajans Adres Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansIl.trim().length === 0) {
				this.showWarning('Başvuran İli Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansUlke.trim().length === 0) {
				this.showWarning('Başvuran Ülkesi Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansWeb.trim().length === 0) {
				this.showWarning('Başvuran Web Sitesi Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansNetwork.trim().length === 0) {
				this.showWarning('Başvuran Bağlı Olduğu Network Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.BasvuranAjansHolding.trim().length === 0) {
				this.showWarning('Başvuran Bağlı Olduğu Ajans Holding Girmelisiniz.');
				hata = true;
				return;
			}

			if (this.PageData.BasvuranAjansYetkililer.length === 0) {
				this.showWarning('En az 1 adet Basvuran Ana Ajans Yetkilisi Girmelisiniz.');
				hata = true;
				return;
			}
			for (let index = 0; index < this.PageData.BasvuranAjansYetkililer.length; index++) {
				const element = this.PageData.BasvuranAjansYetkililer[index];
				if (element.AdSoyad.trim().length === 0 || element.Eposta.trim().length === 0) {
					hata = true;
				}
			}
			if (hata === true) {
				this.showWarning('Ajans Yetkilisi Bilgilerini Doldurunuz.');
				return;
			}

			for (let index = 0; index < this.PageData.BasvuranAjansYetkililer.length; index++) {
				const element = this.PageData.BasvuranAjansYetkililer[index];

				if (!this.validateEmail(element.Eposta.trim())) {
					hata = true;
				}
			}
			if (hata === true) {
				this.showWarning('Geçerli Basvuran Ana Ajans Yetkilisi E-Posta Adresi Girmelisiniz.');
				return;
			}

			// if (this.PageData.KatkidaBulunanAjanslar.length === 0) {
			// 	this.showWarning('En az 1 adet Katkıda Bulunan Ajans Girmelisiniz.');
			// 	hata = true;
			// 	return;
			// }
			// console.log(this.PageData.KatkidaBulunanAjanslar)
			let AjansHata = false;
			this.PageData.KatkidaBulunanAjanslar.forEach(element => {
				if (element.Ajans.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans Adı Girmelisiniz.');
					AjansHata = true;
					return;
				}
				if (element.Il.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans İli Girmelisiniz.');
					AjansHata = true;
					return;
				}
				if (element.Ulke.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans Ülkesi Girmelisiniz.');
					AjansHata = true;
					return;
				}
				if (element.Web.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans Web Sitesi Girmelisiniz.');
					AjansHata = true;
					return;
				}
				if (element.Network.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans Bağlı Olduğu Network Girmelisiniz.');
					AjansHata = true;
					return;
				}
				if (element.Holding.trim().length == 0) {
					this.showWarning('Katkıda Bulunan Ajans Bağlı Olduğu Ajans Holding Girmelisiniz.');
					AjansHata = true;
					return;
				}
			});
			if (AjansHata) {
				hata = true;
				return;
			}

			if (this.PageData.FormuDolduranAdi.trim().length === 0) {
				this.showWarning('Formu Dolduran Adı Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.FormuDolduranGorevi.trim().length === 0) {
				this.showWarning('Formu Dolduran Görevi Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.FormuDolduranTelefon.trim().length === 0) {
				this.showWarning('Formu Dolduran Tel Girmelisiniz.');
				hata = true;
				return;
			}
			if (this.PageData.FormuDolduranEposta.trim().length === 0) {
				this.showWarning('Formu Dolduran E-Posta Adresi Girmelisiniz.');
				hata = true;
				return;
			}
			if (!this.validateEmail(this.PageData.FormuDolduranEposta.trim())) {
				this.showWarning('Geçerli Formu Dolduran E-Posta Adresi Girmelisiniz.');
				hata = true;
				return;
			}


			// return;

			if (JSON.stringify(this.PageData) !== JSON.stringify(this.OrgData)) {
				let UpdateData;

				if (this.IlkSurdurulebilirlik != this.SonSurdurulebilirlik) {
					UpdateData = {
						'MarkaAdi': this.PageData.MarkaAdi,
						'MarkaIl': this.PageData.MarkaIl,
						'MarkaUlke': this.PageData.MarkaUlke,
						'KampanyaAdi': this.PageData.KampanyaAdi,
						'ReklamverenAdi': this.PageData.ReklamverenAdi,
						'ReklamverenAdres': this.PageData.ReklamverenAdres,
						'ReklamverenHolding': this.PageData.ReklamverenHolding,
						'ReklamverenIl': this.PageData.ReklamverenIl,
						'ReklamverenUlke': this.PageData.ReklamverenUlke,
						'ReklamverenWeb': this.PageData.ReklamverenWeb,
						'ReklamverenYetkililer': this.PageData.ReklamverenYetkililer,
						'BasvuranAjansAdi': this.PageData.BasvuranAjansAdi,
						'BasvuranAjansAdres': this.PageData.BasvuranAjansAdres,
						'BasvuranAjansNetwork': this.PageData.BasvuranAjansNetwork,
						'BasvuranAjansHolding': this.PageData.BasvuranAjansHolding,
						'BasvuranAjansIl': this.PageData.BasvuranAjansIl,
						'BasvuranAjansUlke': this.PageData.BasvuranAjansUlke,
						'BasvuranAjansWeb': this.PageData.BasvuranAjansWeb,
						'BasvuranAjansYetkililer': this.PageData.BasvuranAjansYetkililer,
						'BasvuranAjansIkiAdi': this.PageData.BasvuranAjansIkiAdi,
						'BasvuranAjansIkiAdres': this.PageData.BasvuranAjansIkiAdres,
						'BasvuranAjansIkiNetwork': this.PageData.BasvuranAjansIkiNetwork,
						'BasvuranAjansIkiWeb': this.PageData.BasvuranAjansIkiWeb,
						'BasvuranAjansIkiYetkililer': this.PageData.BasvuranAjansIkiYetkililer,
						'KatkidaBulunanAjanslar': this.PageData.KatkidaBulunanAjanslar,
						'FormuDolduranAdi': this.PageData.FormuDolduranAdi,
						'FormuDolduranGorevi': this.PageData.FormuDolduranGorevi,
						'FormuDolduranTelefon': this.PageData.FormuDolduranTelefon,
						'FormuDolduranEposta': this.PageData.FormuDolduranEposta,
						'KategoriId': this.PageData['KategoriId'],
						'SubKategoriId': this.PageData['SubKategoriId'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'SurdurulebilirChanged': 1,
						'Id': this.EntryId
					}
				}
				else {
					UpdateData = {
						'MarkaAdi': this.PageData.MarkaAdi,
						'MarkaIl': this.PageData.MarkaIl,
						'MarkaUlke': this.PageData.MarkaUlke,
						'KampanyaAdi': this.PageData.KampanyaAdi,
						'ReklamverenAdi': this.PageData.ReklamverenAdi,
						'ReklamverenAdres': this.PageData.ReklamverenAdres,
						'ReklamverenHolding': this.PageData.ReklamverenHolding,
						'ReklamverenIl': this.PageData.ReklamverenIl,
						'ReklamverenUlke': this.PageData.ReklamverenUlke,
						'ReklamverenWeb': this.PageData.ReklamverenWeb,
						'ReklamverenYetkililer': this.PageData.ReklamverenYetkililer,
						'BasvuranAjansAdi': this.PageData.BasvuranAjansAdi,
						'BasvuranAjansAdres': this.PageData.BasvuranAjansAdres,
						'BasvuranAjansNetwork': this.PageData.BasvuranAjansNetwork,
						'BasvuranAjansHolding': this.PageData.BasvuranAjansHolding,
						'BasvuranAjansIl': this.PageData.BasvuranAjansIl,
						'BasvuranAjansUlke': this.PageData.BasvuranAjansUlke,
						'BasvuranAjansWeb': this.PageData.BasvuranAjansWeb,
						'BasvuranAjansYetkililer': this.PageData.BasvuranAjansYetkililer,
						'BasvuranAjansIkiAdi': this.PageData.BasvuranAjansIkiAdi,
						'BasvuranAjansIkiAdres': this.PageData.BasvuranAjansIkiAdres,
						'BasvuranAjansIkiNetwork': this.PageData.BasvuranAjansIkiNetwork,
						'BasvuranAjansIkiWeb': this.PageData.BasvuranAjansIkiWeb,
						'BasvuranAjansIkiYetkililer': this.PageData.BasvuranAjansIkiYetkililer,
						'KatkidaBulunanAjanslar': this.PageData.KatkidaBulunanAjanslar,
						'FormuDolduranAdi': this.PageData.FormuDolduranAdi,
						'FormuDolduranGorevi': this.PageData.FormuDolduranGorevi,
						'FormuDolduranTelefon': this.PageData.FormuDolduranTelefon,
						'FormuDolduranEposta': this.PageData.FormuDolduranEposta,
						'KategoriId': this.PageData['KategoriId'],
						'SubKategoriId': this.PageData['SubKategoriId'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'SurdurulebilirChanged': 0,
						'Id': this.EntryId
					}
				}


				// console.log(JSON.stringify(UpdateData));
				// console.log(UpdateData);

				const params = new HttpParams();
				let headers = new HttpHeaders();
				headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
				headers = headers.append('Content-Type', 'application/json');
				headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

				this.http.post('https://api.effieturkiye.org/ProjeKunye', UpdateData, { params, headers }).subscribe(
					(response) => {
						const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
						this.globals.projeler[index] = response;
						this.showSuccess('İkinci Adım Kaydedildi, yönlendiriliyorsunuz.');
						this.messageService.sendMessage('ProjeGuncellendi');
						this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
					},
					error => {
						//  console.log('Error', error)
						this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				);
			}
			else {
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
			}


		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}
	transform(v:string):SafeHtml {
		return this._sanitizer.bypassSecurityTrustHtml(v);
	  }
}
