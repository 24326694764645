<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register" action="#" method="post"
                        *ngIf="Yuklendi === true && PageData.Adim11Durum === 0">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Hedef</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Fikir</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Uygulama</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sonuç</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(10)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim10Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(11)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                                <span class="badge" *ngIf="PageData.Adim11Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">
                                <!-- {{PageData | json}}  -->
                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <p *ngIf="GlobalYonetim.Adim2.Header.length > 0"
                                        [innerHtml]="transform(GlobalYonetim.Adim2.Header)"
                                        style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansAdi">
                                                <span class="VerticalAlignSub">Marka Adı</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-4">
                                                <ng-select #MarkaSelect name="MarkaSelect" [clearable]="false"
                                                    [(ngModel)]="SeciliMarka" [items]="Markalar" bindLabel=""
                                                    bindValue="" [searchable]="true" placeholder="Marka arayın"
                                                    (change)="MarkaChanged($event)" [notFoundText]="'Marka bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewMarka()">Yeni Marka Ekle
                                                        </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #MarkaInput
                                                    [disabled]="(SeciliMarka?.length > 0 && SeciliMarka != ' ')  || (SeciliMarka == null && PageData.MarkaAdi.length == 0)"
                                                    class="form-control" type="text" placeholder="Marka Adı"
                                                    name="MarkaAdi" [(ngModel)]="PageData.MarkaAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="MarkaIl">
                                                <span class="VerticalAlignSub">Marka İli:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Marka İli"
                                                    name="MarkaIl" [(ngModel)]="PageData.MarkaIl">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="MarkaUlke">
                                                <span class="VerticalAlignSub">Marka Ülkesi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Marka Ülkesi"
                                                    name="MarkaUlke" [(ngModel)]="PageData.MarkaUlke">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="KampanyaAdi">
                                                <span class="VerticalAlignSub">Kampanya Adı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Kampanya Adı"
                                                    name="KampanyaAdi" [(ngModel)]="PageData.KampanyaAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="projectinput1">
                                                <span class="VerticalAlignSub">Kategori:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <select class="form-control" name="Kategori"
                                                    [(ngModel)]="PageData.KategoriId"
                                                    (ngModelChange)="KategoriChanged($event)">
                                                    <option *ngFor="let c of Kategoriler" [ngValue]="c.Id">
                                                        {{c.Kategori}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="projectinput1">
                                                <span class="VerticalAlignSub">Alt Kategori:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <select class="form-control" name="SubKategori"
                                                    [(ngModel)]="PageData.SubKategoriId"
                                                    (ngModelChange)="SubKategoriChanged($event)">
                                                    <option *ngFor="let c of TempSubKategoriler" [ngValue]="c.Id">
                                                        {{c.Kategori}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="IlkSurdurulebilirlik != SonSurdurulebilirlik"
                                            style="color: red; font-size: 14px; font-weight: 600;">
                                            Adım 3,4,5,6 değişecek
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;">
                                                <span class="VerticalAlignSub">Reklamveren:</span>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansAdi">
                                                <span class="VerticalAlignSub">Reklamveren Adı</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-4">
                                                <ng-select #ReklamSelect name="ReklamSelect" [clearable]="false"
                                                    [(ngModel)]="SeciliReklamveren" [items]="Reklamverenler"
                                                    bindLabel="" bindValue="" [searchable]="true"
                                                    placeholder="Reklamveren arayın" (change)="ReklamverenChanged($event)"
                                                    [notFoundText]="'Reklamveren bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewReklamveren()">Yeni
                                                            Reklamveren Ekle </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #ReklamInput
                                                    [disabled]="(SeciliReklamveren?.length > 0 && SeciliReklamveren != ' ')  || (SeciliReklamveren == null && PageData.ReklamverenAdi.length == 0)"
                                                    class="form-control" type="text" placeholder="Reklamverenin Adı"
                                                    name="ReklamverenAdi" [(ngModel)]="PageData.ReklamverenAdi">
                                            </div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenAdres">
                                                <span class="VerticalAlignSub">Adresi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control"
                                                    placeholder="Reklamverenin Adres bilgileri" name="ReklamverenAdres"
                                                    [(ngModel)]="PageData.ReklamverenAdres"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenIl">
                                                <span class="VerticalAlignSub">İli:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="İli"
                                                    name="ReklamverenIl" [(ngModel)]="PageData.ReklamverenIl">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenUlke">
                                                <span class="VerticalAlignSub">Ülkesi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Ülkesi"
                                                    name="ReklamverenUlke" [(ngModel)]="PageData.ReklamverenUlke">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenHolding">
                                                <span class="VerticalAlignSub">Network & Holding:</span>
                                                <span class="gerekli"> *</span>
                                            </label>

                                            <div class="col-md-4">
                                                <ng-select #SelectReklamverenHolding name="SelectReklamverenHolding"
                                                    [clearable]="false" [(ngModel)]="SeciliReklamverenHolding"
                                                    [items]="ReklamverenHolding" bindLabel="" bindValue=""
                                                    [searchable]="true" placeholder="Network & Holding arayın"
                                                    (change)="ReklamverenHoldingChanged($event)"
                                                    [notFoundText]="'Network & Holding bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewReklamverenHolding()">
                                                            Yeni
                                                            Network & Holding Ekle </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #InputReklamverenHolding
                                                    [disabled]="(SeciliReklamverenHolding?.length > 0 && SeciliReklamverenHolding != ' ')  || (SeciliReklamverenHolding == null && PageData.ReklamverenHolding.length == 0)"
                                                    class="form-control" type="text" placeholder="Network & Holding"
                                                    name="ReklamverenHolding" [(ngModel)]="PageData.ReklamverenHolding">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenWebSitesi">
                                                <span class="VerticalAlignSub">Web Sitesi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Web Sitesi"
                                                    name="ReklamverenWebSitesi" [(ngModel)]="PageData.ReklamverenWeb">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight"
                                            *ngFor="let item of PageData.ReklamverenYetkililer; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="ReklamverenYetkililer{{i}}">
                                                <span class="VerticalAlignSub">Yetkili {{i + 1}}, Pozisyon:</span>
                                                <span class="gerekli" *ngIf="i == 0"> *</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="ReklamverenYetkiliSilSwal"
                                                    (confirm)="ReklamverenYetkiliSil(i)">
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text"
                                                    placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )"
                                                    name="ReklamverenYetkililer{{i}}Adi"
                                                    [(ngModel)]="PageData.ReklamverenYetkililer[i]['AdSoyad']">
                                                <input class="form-control" type="email" placeholder="ornek@effie.org"
                                                    name="ReklamverenYetkililer{{i}}Eposta"
                                                    [(ngModel)]="PageData.ReklamverenYetkililer[i]['Eposta']">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;"
                                                style="text-align: center;">
                                                <button type="button" class="EkleButton" id="ReklamverenYetkiliBut"
                                                    name="ReklamverenYetkiliBut"
                                                    (click)="ModalOpen(ReklamverenYeniModal)">Yetkili Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;"
                                                for="projectinput1">
                                                <span class="VerticalAlignSub">Başvuran Ana Ajans:</span>
                                            </label>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansAdi">
                                                <span class="VerticalAlignSub">Ajans Adı</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-4">
                                                <ng-select #AjansSelect name="AjansSelect" [clearable]="false"
                                                    [(ngModel)]="SeciliAjansAdi" [items]="Ajanslar" bindLabel=""
                                                    bindValue="" [searchable]="true" placeholder="Ajans arayın"
                                                    (change)="AnaAjansChanged($event)"
                                                    [notFoundText]="'Ajans bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewAjans()">Yeni Ajans Ekle
                                                        </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #AjansInput
                                                    [disabled]="(SeciliAjansAdi?.length > 0 && SeciliAjansAdi != ' ')  || (SeciliAjansAdi == null && PageData.BasvuranAjansAdi.length == 0)"
                                                    class="form-control" type="text" placeholder="Ajansın Adı"
                                                    name="BasvuranAjansAdi" [(ngModel)]="PageData.BasvuranAjansAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansAdres">
                                                <span class="VerticalAlignSub">Adresi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" placeholder="Ajansın Adres bilgileri"
                                                    name="BasvuranAjansAdres"
                                                    [(ngModel)]="PageData.BasvuranAjansAdres"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansIl">
                                                <span class="VerticalAlignSub">İli:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="İli"
                                                    name="BasvuranAjansIl" [(ngModel)]="PageData.BasvuranAjansIl">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansUlke">
                                                <span class="VerticalAlignSub">Ülke:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Ülkesi"
                                                    name="BasvuranAjansUlke" [(ngModel)]="PageData.BasvuranAjansUlke">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansWebSitesi">
                                                <span class="VerticalAlignSub">Web Sitesi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Web Sitesi"
                                                    name="BasvuranAjansWebSitesi"
                                                    [(ngModel)]="PageData.BasvuranAjansWeb">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansBagliOlduguNetwork">
                                                <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                                <span class="gerekli"> *</span>
                                            </label>

                                            <div class="col-md-4">
                                                <ng-select #SelectBasvuranNetwork name="SelectBasvuranNetwork"
                                                    [clearable]="false" [(ngModel)]="SeciliBasvuranNetwork"
                                                    [items]="BasvuranNetwork" bindLabel="" bindValue=""
                                                    [searchable]="true" placeholder="Network arayın"
                                                    (change)="BasvuranNetworkChanged($event)"
                                                    [notFoundText]="'Network bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewBasvuranNetwork()">Yeni
                                                            Network Ekle </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #InputBasvuranNetwork
                                                    [disabled]="(SeciliBasvuranNetwork?.length > 0 && SeciliBasvuranNetwork != ' ')  || (SeciliBasvuranNetwork == null && PageData.BasvuranAjansNetwork.length == 0)"
                                                    class="form-control" type="text" placeholder="Bağlı Olduğu Network"
                                                    name="BasvuranNetwork" [(ngModel)]="PageData.BasvuranAjansNetwork">
                                            </div>

                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansBagliOlduguHolding">
                                                <span class="VerticalAlignSub">Bağlı Olduğu Ajans Holding:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-4">
                                                <ng-select #SelectBasvuranHolding name="SelectBasvuranHolding"
                                                    [clearable]="false" [(ngModel)]="SeciliBasvuranHolding"
                                                    [items]="BasvuranHolding" bindLabel="" bindValue=""
                                                    [searchable]="true" placeholder="Holding arayın"
                                                    (change)="BasvuranHoldingChanged($event)"
                                                    [notFoundText]="'Holding bulunamadı'">
                                                    <ng-template ng-footer-tmp>
                                                        <p class="create-new" (click)="CreateNewBasvuranHolding()">Yeni
                                                            Holding Ekle </p>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <label class="col-md-1 label-control"
                                                style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                            <div class="col-md-4">
                                                <input #InputBasvuranHolding
                                                    [disabled]="(SeciliBasvuranHolding?.length > 0 && SeciliBasvuranHolding != ' ')  || (SeciliBasvuranHolding == null && PageData.BasvuranAjansHolding.length == 0)"
                                                    class="form-control" type="text" placeholder="Bağlı Olduğu Ajans Holding"
                                                    name="BasvuranHolding" [(ngModel)]="PageData.BasvuranAjansHolding">
                                            </div>

                                        </div>
                                        <div class="form-group row NoMarginLeftRight"
                                            *ngFor="let item of PageData.BasvuranAjansYetkililer; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="BasvuranAjansYetkililer{{i}}">
                                                <span class="VerticalAlignSub">Yetkili {{i + 1}}, Pozisyon:</span>
                                                <span class="gerekli" *ngIf="i == 0"> *</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="BasvuranAjansYetkiliSilSwal"
                                                    (confirm)="BasvuranAjansYetkiliSil(i)">
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text"
                                                    placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )"
                                                    name="BasvuranAjansYetkililer{{i}}Adi"
                                                    [(ngModel)]="PageData.BasvuranAjansYetkililer[i]['AdSoyad']">
                                                <input class="form-control" type="email" placeholder="ornek@effie.org"
                                                    name="BasvuranAjansYetkililer{{i}}Eposta"
                                                    [(ngModel)]="PageData.BasvuranAjansYetkililer[i]['Eposta']">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control"
                                                style="color: white; text-align: center;">
                                                <button type="button" class="EkleButton"
                                                    (click)="ModalOpen(BasvuranAjansYeniModal)">Yetkili Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- 
                                    <p *ngIf="GlobalYonetim.Adim2.Header2Ajans.length > 0" [innerHtml]="GlobalYonetim.Adim2.Header2Ajans" style="max-width: none; width: 100%; margin-bottom: 10px"></p>
                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;" for="projectinput1">
                                                <span class="VerticalAlignSub">B Ajansı:</span>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansAdi">
                                                <span class="VerticalAlignSub">Ajans Seçiniz</span>
                                            </label>
                                            <div class="col-md-9">
                                                <ng-select [items]="Ajanslar" bindLabel="" bindValue="" [searchable]="true" placeholder="Ajans arayın" (change)="AnaAjansIkiChanged($event)" [notFoundText]="'Ajans bulunamadı'">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;text-align:center;" for="projectinput1">
                                                <span class="VerticalAlignSub">&nbsp;</span>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansIkiAdi">
                                                <span class="VerticalAlignSub">Adı:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="B Ajansın Adı" name="BasvuranAjansIkiAdi" [(ngModel)]="PageData.BasvuranAjansIkiAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansIkiAdres">
                                                <span class="VerticalAlignSub">Adresi:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" placeholder="B Ajansın Adres bilgileri" name="BasvuranAjansIkiAdres" [(ngModel)]="PageData.BasvuranAjansIkiAdres"></textarea>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansIkiBagliOlduguNetwork">
                                                <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Bağlı Olduğu Network" name="BasvuranAjansIkiBagliOlduguNetwork" [(ngModel)]="PageData.BasvuranAjansIkiNetwork">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansIkiWebSitesi">
                                                <span class="VerticalAlignSub">Web Sitesi:</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Web Sitesi" name="BasvuranAjansIkiWebSitesi" [(ngModel)]="PageData.BasvuranAjansIkiWeb">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" *ngFor="let item of PageData.BasvuranAjansIkiYetkililer; let i = index;">
                                            <label class="col-md-3 label-control" style="color: white;" for="BasvuranAjansIkiYetkililer{{i}}">
                                                <span class="VerticalAlignSub">Yetkili {{i + 1}}, Pozisyon:</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="BasvuranAjansIkiYetkiliSilSwal"
                                                    (confirm)="BasvuranAjansIkiYetkiliSil(i)">
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text" placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )" name="BasvuranAjansIkiYetkililer{{i}}Adi" [(ngModel)]="PageData.BasvuranAjansIkiYetkililer[i]['AdSoyad']">
                                                <input class="form-control" type="email" placeholder="ornek@effie.org" name="BasvuranAjansIkiYetkililer{{i}}Eposta" [(ngModel)]="PageData.BasvuranAjansIkiYetkililer[i]['Eposta']">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white; text-align: center;">
                                                <button class="EkleButton"
                                                    (click)="ModalOpen(BasvuranAjansIkiYeniModal)">Yetkili Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div> 
                                    -->

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="projectinput1">
                                                <span class="VerticalAlignSub">Katkıda Bulunan Ajanslar</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9" style="color: #fff;">Başvuran Ana Ajansın yanı sıra,
                                                kampanyanın çeşitli alan/disiplinlerde genişlemesini sağlayan/katkıda
                                                bulunan ajansları lütfen belirtiniz. (Maksimum 4 Adet)</div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight"
                                            *ngIf="PageData.KatkidaBulunanAjanslar.length > 0">
                                            <label class="col-md-3 label-control" for="KatkidaBulunanAjanslar1"
                                                style="color: white;">
                                                <span class="VerticalAlignSub">Ajans 1:</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="KatkidaBulunanAjansSilSwal"
                                                    (confirm)="KatkidaBulunanAjansSil(0)">
                                            </label>
                                            <div class="col-md-9">

                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" for="KatkidaBulunanAjansAdi1"
                                                        style="color: white;">
                                                        <span class="VerticalAlignSub">Ajans Adı</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanAjans1
                                                            id="SelectKatkidaBulunanAjans1"
                                                            name="SelectKatkidaBulunanAjans1" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanAjans1"
                                                            [items]="Ajanslar" [searchable]="true"
                                                            placeholder="Ajans arayın"
                                                            (change)="KatkidaBulunanAjans1Changed($event)"
                                                            [notFoundText]="'Ajans bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanAjans1()">Yeni Ajans
                                                                    Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white; text-align:center;" for="projectinput1">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanAjans1
                                                            for="InputKatkidaBulunanAjans1"
                                                            [disabled]="(SeciliKatkidaBulunanAjans1?.length > 0 && SeciliKatkidaBulunanAjans1 != ' ')  || (SeciliKatkidaBulunanAjans1 == null && PageData.KatkidaBulunanAjanslar[0].Ajans.length == 0)"
                                                            class="form-control" type="text" placeholder="Ajans Adı"
                                                            name="YeniKatkidaBulunanAjans1"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Ajans">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar1Il">
                                                        <span class="VerticalAlignSub">İli:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="İli"
                                                            name="KatkidaBulunanAjanslar1Il"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Il">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar1Ulke">
                                                        <span class="VerticalAlignSub">Ülkesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Ülkesi"
                                                            name="KatkidaBulunanAjanslar1Ulke"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Ulke">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar1Web">
                                                        <span class="VerticalAlignSub">Web Sitesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Web Sitesi"
                                                            name="KatkidaBulunanAjanslar1Web"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Web">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarNetwork1">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanNetwork1
                                                            name="SelectKatkidaBulunanNetwork1" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanNetwork1"
                                                            [items]="BasvuranNetwork" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Network arayın"
                                                            (change)="KatkidaBulunanNetwork1Changed($event)"
                                                            [notFoundText]="'Network bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanNetwork1()">Yeni
                                                                    Network Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput1">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanNetwork1
                                                            [disabled]="(SeciliKatkidaBulunanNetwork1?.length > 0 && SeciliKatkidaBulunanNetwork1 != ' ')   || (SeciliKatkidaBulunanNetwork1 == null && PageData.KatkidaBulunanAjanslar[0].Network.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Network"
                                                            name="KatkidaBulunanNetwork1"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Network">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarHolding1">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Ajans Holding:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanHolding1
                                                            name="SelectKatkidaBulunanHolding1" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanHolding1"
                                                            [items]="BasvuranHolding" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Holding arayın"
                                                            (change)="KatkidaBulunanHolding1Changed($event)"
                                                            [notFoundText]="'Holding bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanHolding1()">Yeni
                                                                    Holding Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput1">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanHolding1
                                                            [disabled]="(SeciliKatkidaBulunanHolding1?.length > 0 && SeciliKatkidaBulunanHolding1 != ' ')  || (SeciliKatkidaBulunanHolding1 == null && PageData.KatkidaBulunanAjanslar[0].Holding.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Ajans Holding"
                                                            name="KatkidaBulunanHolding1"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[0].Holding">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight"
                                            *ngIf="PageData.KatkidaBulunanAjanslar.length > 1">
                                            <label class="col-md-3 label-control" for="KatkidaBulunanAjanslar2"
                                                style="color: white;">
                                                <span class="VerticalAlignSub">Ajans 2:</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="KatkidaBulunanAjansSilSwal"
                                                    (confirm)="KatkidaBulunanAjansSil(1)">
                                            </label>
                                            <div class="col-md-9">

                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" for="KatkidaBulunanAjansAdi2"
                                                        style="color: white;">
                                                        <span class="VerticalAlignSub">Ajans Ad</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanAjans2
                                                            id="SelectKatkidaBulunanAjans2"
                                                            name="SelectKatkidaBulunanAjans2" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanAjans2"
                                                            [items]="Ajanslar" [searchable]="true"
                                                            placeholder="Ajans arayın"
                                                            (change)="KatkidaBulunanAjans2Changed($event)"
                                                            [notFoundText]="'Ajans bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanAjans2()">Yeni Ajans
                                                                    Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white; text-align:center;" for="projectinput2">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanAjans2
                                                            for="InputKatkidaBulunanAjans2"
                                                            [disabled]="(SeciliKatkidaBulunanAjans2?.length > 0 && SeciliKatkidaBulunanAjans2 != ' ')  || (SeciliKatkidaBulunanAjans2 == null && PageData.KatkidaBulunanAjanslar[1].Ajans.length == 0)"
                                                            class="form-control" type="text" placeholder="Ajans Adı"
                                                            name="YeniKatkidaBulunanAjans2"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Ajans">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar2Il">
                                                        <span class="VerticalAlignSub">İli:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="İli"
                                                            name="KatkidaBulunanAjanslar2Il"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Il">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar2Ulke">
                                                        <span class="VerticalAlignSub">Ülkesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Ülkesi"
                                                            name="KatkidaBulunanAjanslar2Ulke"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Ulke">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar2Web">
                                                        <span class="VerticalAlignSub">Web Sitesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Web Sitesi"
                                                            name="KatkidaBulunanAjanslar2Web"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Web">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarNetwork2">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanNetwork2
                                                            name="SelectKatkidaBulunanNetwork2" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanNetwork2"
                                                            [items]="BasvuranNetwork" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Network arayın"
                                                            (change)="KatkidaBulunanNetwork2Changed($event)"
                                                            [notFoundText]="'Network bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanNetwork2()">Yeni
                                                                    Network Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput1">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanNetwork2
                                                            [disabled]="(SeciliKatkidaBulunanNetwork2?.length > 0 && SeciliKatkidaBulunanNetwork2 != ' ') || (SeciliKatkidaBulunanNetwork2 == null && PageData.KatkidaBulunanAjanslar[1].Network.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Network"
                                                            name="KatkidaBulunanNetwork2"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Network">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarHolding1">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Ajans Holding:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanHolding2
                                                            name="SelectKatkidaBulunanHolding2" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanHolding2"
                                                            [items]="BasvuranHolding" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Holding arayın"
                                                            (change)="KatkidaBulunanHolding2Changed($event)"
                                                            [notFoundText]="'Holding bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanHolding2()">Yeni
                                                                    Holding Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput2">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanHolding2
                                                            [disabled]="(SeciliKatkidaBulunanHolding2?.length > 0 && SeciliKatkidaBulunanHolding2 != ' ') || (SeciliKatkidaBulunanHolding2 == null && PageData.KatkidaBulunanAjanslar[1].Holding.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Ajans Holding"
                                                            name="KatkidaBulunanHolding2"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[1].Holding">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight"
                                            *ngIf="PageData.KatkidaBulunanAjanslar.length > 2">
                                            <label class="col-md-3 label-control" for="KatkidaBulunanAjanslar3"
                                                style="color: white;">
                                                <span class="VerticalAlignSub">Ajans 3:</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="KatkidaBulunanAjansSilSwal"
                                                    (confirm)="KatkidaBulunanAjansSil(2)">
                                            </label>
                                            <div class="col-md-9">

                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" for="KatkidaBulunanAjansAdi3"
                                                        style="color: white;">
                                                        <span class="VerticalAlignSub">Ajans Adı</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanAjans3
                                                            id="SelectKatkidaBulunanAjans3"
                                                            name="SelectKatkidaBulunanAjans3" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanAjans3"
                                                            [items]="Ajanslar" [searchable]="true"
                                                            placeholder="Ajans arayın"
                                                            (change)="KatkidaBulunanAjans3Changed($event)"
                                                            [notFoundText]="'Ajans bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanAjans3()">Yeni Ajans
                                                                    Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white; text-align:center;" for="projectinput3">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanAjans3
                                                            for="InputKatkidaBulunanAjans3"
                                                            [disabled]="(SeciliKatkidaBulunanAjans3?.length > 0 && SeciliKatkidaBulunanAjans3 != ' ')  || (SeciliKatkidaBulunanAjans3 == null && PageData.KatkidaBulunanAjanslar[2].Ajans.length == 0)"
                                                            class="form-control" type="text" placeholder="Adı"
                                                            name="YeniKatkidaBulunanAjans3"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Ajans">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar3Il">
                                                        <span class="VerticalAlignSub">İli:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="İli"
                                                            name="KatkidaBulunanAjanslar3Il"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Il">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar3Ulke">
                                                        <span class="VerticalAlignSub">Ülkesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Ülkesi"
                                                            name="KatkidaBulunanAjanslar3Ulke"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Ulke">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar3Web">
                                                        <span class="VerticalAlignSub">Web Sitesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Web Sitesi"
                                                            name="KatkidaBulunanAjanslar3Web"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Web">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarNetwork3">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanNetwork3
                                                            name="SelectKatkidaBulunanNetwork3" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanNetwork3"
                                                            [items]="BasvuranNetwork" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Network arayın"
                                                            (change)="KatkidaBulunanNetwork3Changed($event)"
                                                            [notFoundText]="'Network bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanNetwork3()">Yeni
                                                                    Network Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput3">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanNetwork3
                                                            [disabled]="(SeciliKatkidaBulunanNetwork3?.length > 0 && SeciliKatkidaBulunanNetwork3 != ' ')  || (SeciliKatkidaBulunanNetwork3 == null && PageData.KatkidaBulunanAjanslar[2].Network.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Network"
                                                            name="KatkidaBulunanNetwork3"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Network">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarHolding3">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Ajans Holding:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanHolding3
                                                            name="SelectKatkidaBulunanHolding3" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanHolding3"
                                                            [items]="BasvuranHolding" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Holding arayın"
                                                            (change)="KatkidaBulunanHolding3Changed($event)"
                                                            [notFoundText]="'Holding bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanHolding3()">Yeni
                                                                    Holding Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput1">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanHolding3
                                                            [disabled]="(SeciliKatkidaBulunanHolding3?.length > 0 && SeciliKatkidaBulunanHolding3 != ' ')  || (SeciliKatkidaBulunanHolding3 == null && PageData.KatkidaBulunanAjanslar[2].Holding.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Ajans Holding"
                                                            name="KatkidaBulunanHolding3"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[2].Holding">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight"
                                            *ngIf="PageData.KatkidaBulunanAjanslar.length > 3">
                                            <label class="col-md-3 label-control" for="KatkidaBulunanAjanslar3"
                                                style="color: white;">
                                                <span class="VerticalAlignSub">Ajans 4:</span>
                                                <img src="../../assets/eksi.png" class="arti"
                                                    [swal]="KatkidaBulunanAjansSilSwal"
                                                    (confirm)="KatkidaBulunanAjansSil(3)">
                                            </label>
                                            <div class="col-md-9">

                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" for="KatkidaBulunanAjansAdi4"
                                                        style="color: white;">
                                                        <span class="VerticalAlignSub">Ajans Adı</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanAjans4
                                                            id="SelectKatkidaBulunanAjans4"
                                                            name="SelectKatkidaBulunanAjans4" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanAjans4"
                                                            [items]="Ajanslar" [searchable]="true"
                                                            placeholder="Ajans arayın"
                                                            (change)="KatkidaBulunanAjans4Changed($event)"
                                                            [notFoundText]="'Ajans bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanAjans4()">Yeni Ajans
                                                                    Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white; text-align:center;" for="projectinput4">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanAjans4
                                                            for="InputKatkidaBulunanAjans4"
                                                            [disabled]="(SeciliKatkidaBulunanAjans4?.length > 0 && SeciliKatkidaBulunanAjans4 != ' ')  || (SeciliKatkidaBulunanAjans4 == null && PageData.KatkidaBulunanAjanslar[3].Ajans.length == 0)"
                                                            class="form-control" type="text" placeholder="Ajans Adı"
                                                            name="YeniKatkidaBulunanAjans4"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Ajans">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar4Il">
                                                        <span class="VerticalAlignSub">İli:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="İli"
                                                            name="KatkidaBulunanAjanslar4Il"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Il">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar4Ulke">
                                                        <span class="VerticalAlignSub">Ülkesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Ülkesi"
                                                            name="KatkidaBulunanAjanslar4Ulke"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Ulke">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslar4Web">
                                                        <span class="VerticalAlignSub">Web Sitesi:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-9">
                                                        <input class="form-control" type="text" placeholder="Web Sitesi"
                                                            name="KatkidaBulunanAjanslar4Web"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Web">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarNetwork4">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Network:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanNetwork4
                                                            name="SelectKatkidaBulunanNetwork4" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanNetwork4"
                                                            [items]="BasvuranNetwork" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Network arayın"
                                                            (change)="KatkidaBulunanNetwork4Changed($event)"
                                                            [notFoundText]="'Network bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanNetwork4()">Yeni
                                                                    Network Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput4">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanNetwork4
                                                            [disabled]="(SeciliKatkidaBulunanNetwork4?.length > 0 && SeciliKatkidaBulunanNetwork4 != ' ')  || (SeciliKatkidaBulunanNetwork4 == null && PageData.KatkidaBulunanAjanslar[3].Network.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Network"
                                                            name="KatkidaBulunanNetwork4"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Network">
                                                    </div>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-3 label-control" style="color: white;"
                                                        for="KatkidaBulunanAjanslarHolding4">
                                                        <span class="VerticalAlignSub">Bağlı Olduğu Ajans Holding:</span>
                                                        <span class="gerekli"> *</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <ng-select #SelectKatkidaBulunanHolding4
                                                            name="SelectKatkidaBulunanHolding4" [clearable]="false"
                                                            [(ngModel)]="SeciliKatkidaBulunanHolding4"
                                                            [items]="BasvuranHolding" bindLabel="" bindValue=""
                                                            [searchable]="true" placeholder="Holding arayın"
                                                            (change)="KatkidaBulunanHolding4Changed($event)"
                                                            [notFoundText]="'Holding bulunamadı'">
                                                            <ng-template ng-footer-tmp>
                                                                <p class="create-new"
                                                                    (click)="CreateNewKatkidaBulunanHolding4()">Yeni
                                                                    Holding Ekle </p>
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                    <label class="col-md-1 label-control"
                                                        style="color: white;text-align:center;" for="projectinput4">
                                                        <span class="VerticalAlignSub">&nbsp;</span>
                                                    </label>
                                                    <div class="col-md-4">
                                                        <input #InputKatkidaBulunanHolding4
                                                            [disabled]="(SeciliKatkidaBulunanHolding4?.length > 0 && SeciliKatkidaBulunanHolding4 != ' ')  || (SeciliKatkidaBulunanHolding4 == null && PageData.KatkidaBulunanAjanslar[3].Holding.length == 0)"
                                                            class="form-control" type="text"
                                                            placeholder="Bağlı Olduğu Ajans Holding"
                                                            name="KatkidaBulunanHolding4"
                                                            [(ngModel)]="PageData.KatkidaBulunanAjanslar[3].Holding">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row NoMarginLeftRight"
                                            *ngIf="PageData.KatkidaBulunanAjanslar.length < 4">
                                            <label class="col-md-12 label-control"
                                                style="color: white; text-align: center;">
                                                <button type="button" class="EkleButton"
                                                    (click)="KatkidaBulunanAjansEkle()">Ajans Ekle <img
                                                        src="../../assets/arti.png" class="arti"
                                                        style="right: initial;" /></button>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="projectinput1">
                                                <span class="VerticalAlignSub">Formu Dolduran:</span>
                                            </label>
                                            <div class="col-md-9">&nbsp;</div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="FormuDolduranAdi">
                                                <span class="VerticalAlignSub">Adı, Soyadı:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text"
                                                    placeholder="Formu Dolduran Kişinin Adı, Soyadı"
                                                    name="FormuDolduranAdi" [(ngModel)]="PageData.FormuDolduranAdi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="FormuDolduranGorevi">
                                                <span class="VerticalAlignSub">Görevi:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="text"
                                                    placeholder="Formu Dolduran Kişinin Görevi"
                                                    name="FormuDolduranGorevi"
                                                    [(ngModel)]="PageData.FormuDolduranGorevi">
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="FormuDolduranTel">
                                                <span class="VerticalAlignSub">Telefon: </span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <!-- (ngModelChange)="phoneMask($event)" -->
                                                <input mask="(000) 000 00 00" class="form-control" type="tel"
                                                    id="FormuDolduranTel" placeholder="Formu Dolduran Kişinin Telefonu"
                                                    name="FormuDolduranTel" maxlength="15"
                                                    [(ngModel)]="PageData.FormuDolduranTelefon">
                                                <span style="font-weight: 400;color: white;">Telefon numaranızı başında
                                                    0 olmadan yazınız.</span>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;"
                                                for="FormuDolduranEposta">
                                                <span class="VerticalAlignSub">E-Posta:</span>
                                                <span class="gerekli"> *</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input class="form-control" type="email"
                                                    placeholder="Formu Dolduran Kişinin E-Posta Adresi"
                                                    name="FormuDolduranEposta"
                                                    [(ngModel)]="PageData.FormuDolduranEposta">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(1)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(3)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>

    <ng-template #ReklamverenYeniModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Reklamveren Yetkili Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="ReklamverenYetkililer">
                    <span class="VerticalAlignSub">Ad Soyad, Pozisyon:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text"
                        placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )"
                        name="YeniReklamverenYetkiliAdi" [(ngModel)]="YeniReklamverenYetkiliAdi">
                </div>
            </div>
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="ReklamverenYetkililer">
                    <span class="VerticalAlignSub">E-Posta:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text" placeholder="ornek@effie.org"
                        name="YeniReklamverenYetkiliEposta" [(ngModel)]="YeniReklamverenYetkiliEposta">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat"
                (click)="c('')">Kapat</button>
            <button type="button" class="btn btn-block btn-raised white float-right ModalEkle"
                (click)="ReklamverenYeniModalSave()">Ekle</button>
        </div>
    </ng-template>

    <ng-template #BasvuranAjansYeniModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Başvuran Ana Ajans Yetkili Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="BasvuranAjansYetkililer">
                    <span class="VerticalAlignSub">Ad Soyad, Pozisyon:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text"
                        placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )"
                        name="YeniBasvuranAjansYetkiliAdi" [(ngModel)]="YeniBasvuranAjansYetkiliAdi">
                </div>
            </div>
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="BasvuranAjansYetkililer">
                    <span class="VerticalAlignSub">E-Posta:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text" placeholder="ornek@effie.org"
                        name="YeniBasvuranAjansYetkiliEposta" [(ngModel)]="YeniBasvuranAjansYetkiliEposta">
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat"
                (click)="c('')">Kapat</button>
            <button type="button" class="btn btn-block btn-raised white float-right ModalEkle"
                (click)="BasvuranAjansYeniModalSave()">Ekle</button>
        </div>
    </ng-template>

    <ng-template #BasvuranAjansIkiYeniModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Başvuran İkinci Ana Ajans Yetkili Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="BasvuranAjansIkiYetkililer">
                    <span class="VerticalAlignSub">Ad Soyad, Pozisyon:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text"
                        placeholder="Ahmet Çetin, Gen. Md. (Pazarlama Md., Reklam Md., Ürün Md., vb. )"
                        name="YeniBasvuranAjansIkiYetkiliAdi" [(ngModel)]="YeniBasvuranAjansIkiYetkiliAdi">
                </div>
            </div>
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="BasvuranAjansIkiYetkililer">
                    <span class="VerticalAlignSub">E-Posta:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input class="form-control" type="text" placeholder="ornek@effie.org"
                        name="YeniBasvuranAjansIkiYetkiliEposta" [(ngModel)]="YeniBasvuranAjansIkiYetkiliEposta">
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat"
                (click)="c('')">Kapat</button>
            <button type="button" class="btn btn-block btn-raised white float-right ModalEkle"
                (click)="BasvuranAjansIkiYeniModalSave()">Ekle</button>
        </div>
    </ng-template>


</section>