<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <header class="dev-entrant-layout">
                <h1>Çalışma Dosyası</h1>
            </header>
            <div style="margin-top: 10px;" [innerHtml]="Icerik">

            </div>
            <br>
            <a class="PDFButton" href="https://basvuru.effieturkiye.org/Effie_2025_Basvuru_Kiti.zip" target="_blank">Dosyayı İndir</a>
        </article>
    </div>
</section>